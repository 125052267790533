export default {
  appSettings: {
    debug: false,
    api_url: 'https://api.mitu.tv/mrss/ade-x1.mrss',
    config_url: 'https://api.mitu.tv/mrss/x1-config.json',
    env: 'production',
    keys: {
      '8': 'Back',
      '13': 'Enter',
      '27': 'Exit',
      '33': 'PageUp',
      '34': 'PageDown',
      '37': 'Left',
      '38': 'Up',
      '39': 'Right',
      '40': 'Down',
      '68': 'Debug',
      '71': 'Guide',
      '72': 'Hint',
      '73': 'Info',
      '76': 'Last',
      '77': 'Menu',
      '78': 'Next',
      '80': 'Prev',
      '83': 'Star',
      '84': 'TextBanner',
      '86': 'VoiceGuidance',
      '179': 'PlayPause',
      '219': 'Rewind',
      '221': 'FastForward',
      '227': 'Rewind',
      '228': 'FastForward',
      '403': 'ColorA',
      '404': 'ColorB',
      '405': 'ColorC',
      '406': 'ColorD',
    },
    APP_MUX_ENV_KEY: 'abi38lce0k2m5r5tledc8c6f5',
    APP_MUX_DEBUG: true,
    APP_SHORT_NAME: 'mitu',
    APP_ERROR_CODE: 'MITU',
    ADS_ENABLED: false,
    UP_NEXT_TIME_IN_SECONDS: 20,
    UP_NEXT_OFFSET_FROM_END: 3,
    UP_NEXT_DISABLED: false,
    DEBUG_UP_NEXT: false,
    SENTRY_DSN:
      'https://c080a74a21bd6200e49f9b5c3526d8fa@o210186.ingest.sentry.io/4505646442348544',
    SENTRY_PROJECT: 'mitu',
    FIREBASE_APP_ID: '1:311719570977:web:c449fb696c86f2ea50bd60',
    FIREBASE_MEASUREMENT_ID: 'G-C3PXCZDEF6',
    PLAUSIBLE_DOMAIN: 'mitu.adeengine.com',
    FORCE_GA_TAG: true,
    enablePointer: false,
    SCREENSAVER_ENABLED: true,
    SCREENSAVER_TIMEOUT_IN_MINUTES: 30,
    MAX_VIDEOS_BEFORE_CHECK: 5,
    ARE_YOU_STILL_THERE_TIMEOUT_IN_SECONDS: 20,
    stage: {
      clearColor: '0x00000000',
    },
    version: '2024.5.3.0',
    buildDate: 'Mon May 06 2024 11:37:36 GMT-0700 (Pacific Daylight Time)',
    appMetadata: {
      name: 'mitu',
      identifier: 'co.weade.mitu',
      description: 'Mitu App',
      author: 'A Different Engine <hi@adifferentengine.com>',
      icon: './images/icon.png',
      lg: {
        id: 'co.adeengine.mitu',
        version: '2024.5.0',
        vendor: 'A Different Engine',
        type: 'web',
        main: 'index.html',
        title: 'Mitú TV',
        bgColor: '#1c2029',
        resolution: '1920x1080',
        disableBackHistoryAPI: true,
        icon: 'icon.png',
        largeIcon: 'largeIcon.png',
      },
      version: '2024.5.3.0',
      hash: 'a08b9d3',
      branch: 'feature/app-release-version-push',
      commitDate: 'Last Commit: May 6th, 2024 at 11:37',
      buildDate: 'Mon May 06 2024 11:37:36 GMT-0700 (Pacific Daylight Time)',
    },
    environment: 'production',
    platform: 'x1',
  },
  platformSettings: {
    path: './',
    log: false,
    clearColor: '0x00000000',
    showVersion: false,
    proxyUrl:
      'https://us-central1-valhalla-f2ec8.cloudfunctions.net/feedFM-imageProxy',
    router: {
      backtracking: true,
    },
    inspector: true,
  },
  appData: {},
  appSetttings: {},
}
